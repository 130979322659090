<template>
  <form @submit.prevent="handleSubmit" v-shortkey="saveShortkey" @shortkey="handleSubmit">
    <div class="mx-auto w-full">
      <h4>{{ isEditing ? "Edit" : "Add" }} Insurance</h4>
      <loader v-if="isLoading" size="small" class="mx-auto"></loader>
    </div>
    <div class="d-flex">
      <div class="w-50">
        <div class="d-flex">
          <TextInput
            class="col"
            label="Code"
            name="code"
            v-focus
            v-model="insurance.code"
            :validator="$v.insurance.code"
            :validatorMsgMap="validatorMsgMap"
          />
          <SelectInput
            :multiple="false"
            label="Category"
            name="category"
            class="col"
            v-model="insurance.insuranceCategoryId"
            :items="insuranceCategories"
          />
        </div>

        <div class="d-flex">
          <TextInput class="col" name="provider" label="Provider" v-model="insurance.providerId" />
          <TextInput class="col" name="group" label="Group" v-model="insurance.group" />
        </div>
        <div class="d-flex">
          <TextInput class="col" name="payorId" label="Payor ID" v-model="insurance.payorId" />
          <div class="col" />
        </div>
        <div class="d-flex">
          <SelectInput
            :items="billingRates"
            class="col"
            name="billingRate"
            label="Billing Rate"
            :validator="$v.insurance.billingRateId"
            v-model="insurance.billingRateId"
          />
          <PhoneInput
            name="phone"
            class="col"
            label="Phone Number"
            maxLength="16"
            v-model="insurance.phoneNumber"
            :validator="$v.insurance.phoneNumber"
          />
        </div>
        <div class="d-flex">
          <TextInput class="col" label="Policy Masks" v-model="insurance.policyNumberMasks" />
          <TextInput class="col" label="Group Masks" v-model="insurance.groupNumberMasks" />
        </div>
        <text-area-input
          class="col"
          label="Description"
          v-model="insurance.description"
          :validator="$v.insurance.description"
          maxLength="251"
        />
        <text-area-input
          class="col"
          label="Comments"
          v-model="insurance.comments"
          maxLength="251"
        />
      </div>
      <div class="w-50">
        <SelectInput
          class="col"
          name="insurancePayerId"
          label="Eligibility Id"
          displayExpr="displayName"
          :dataSource="insurancePayersSearch"
          :validator="$v.insurance.insurancePayerId"
          v-model="insurance.insurancePayerId"
        />
        <TextInput
          class="col"
          label="Address Line 1"
          v-model="insurance.addressLine1"
          :validator="$v.insurance.addressLine1"
        />
        <TextInput class="col" label="Address Line 2" v-model="insurance.addressLine2" />
        <address-lookup class="col" v-model="insurance" />
        <div class="d-flex">
          <DatePicker
            :validator="$v.insurance.effectiveOn"
            v-model="insurance.effectiveOn"
            label="Effective On"
            class="col"
          />
          <DatePicker
            v-model="insurance.expiryOn"
            :validator="$v.insurance.expiryOn"
            label="Expires On"
            class="col"
          />
        </div>
      </div>
    </div>

    <CustomFields
      class="w-75 mx-auto"
      v-model="insurance.customFields"
      customFieldType="insurance"
    />
    <div class="w-75 mx-auto mt-3 d-flex justify-content-between">
      <h3>Interface Maps</h3>
      <add-button class="mb-2" @click.prevent="addInterfaceMap" />
    </div>
    <DxDataGrid
      v-show="insurance.interfaceMaps.length"
      :show-borders="true"
      class="w-75 mx-auto"
      :dataSource="insurance.interfaceMaps"
      :columns="interfaceMapColumns"
      :editing="editing"
      @initialized="initGrid"
      :cacheEnabled="false"
    />
    <div class="mt-4 d-flex justify-content-end">
      <button class="btn btn-danger" @click="handleCancel" type="button">Cancel</button>
      <button type="submit" class="mx-2 btn btn-primary">Submit</button>
    </div>
  </form>
</template>

<script>
import PhoneInput from "@/components/common/PhoneInput.vue";
import CustomFields from "@/components/forms/CodeMaintenance/CustomFieldsTable";
import SettingsService from "@/services/settings";
import MacroService from "@/services/macros";
import DropdownService from "@/services/dropdown";
import InsuranceService from "@/services/insurance";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import TextAreaInput from "@/components/TextAreaInput.vue";
import {
  afterEffective,
  altKey,
  createLogComment,
  createLogItem,
  oneYearAgo,
  validatorMsgMapBase
} from "@/modules/helpers";
import auditLog from "@/services/AuditLog";
import { cloneDeep } from "lodash";
import AddressLookup from "@/components/common/AddressLookup.vue";
import TextInput from "@/components/common/TextInput.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import { InsuranceApi } from "@/services";
import Loader from "@/components/common/Loader.vue";
import AddButton from "@/components/common/AddButton.vue";
import { DxDataGrid } from "devextreme-vue/data-grid";

export default {
  name: "InsuranceMaintenance",
  components: {
    PhoneInput,
    CustomFields,
    TextAreaInput,
    AddressLookup,
    TextInput,
    DatePicker,
    SelectInput,
    Loader,
    AddButton,
    DxDataGrid
  },
  props: ["insuranceId", "cloneData"],
  data() {
    return {
      isLoading: false,
      originalInsurance: {},
      insurance: {
        labId: "",
        code: "",
        description: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        providerId: "",
        group: "",
        billingRateId: null,
        insuranceCatergoryId: null,
        policyNumberMask: "",
        groupNumberMask: "",
        effectiveOn: oneYearAgo(),
        expiryOn: null,
        customFields: [],
        interfaceMaps: [],
        payorId: "",
        comments: ""
      },
      insurancePayersSearch: DropdownService.searchInsurancePayers,
      panelSearch: MacroService.searchStore,
      insuranceStore: InsuranceService.searchStore,
      insuranceCategories: [],
      billingRates: [],
      saveShortkey: altKey("s"),
      interfaceMapColumns: [
        {
          caption: "Receiving Application",
          dataField: "receivingApp",
          dataType: "string",
          allowEditing: true
        },
        {
          caption: "Receiving Facility",
          dataField: "receivingFac",
          dataType: "string",
          allowEditing: true
        },
        {
          caption: "EMR Insurance Value",
          dataField: "emrInsurance",
          dataType: "string",
          allowEditing: true
        }
      ],
      grid: {}
    };
  },
  validations() {
    return {
      insurance: {
        code: {
          required,
          unique: async value => {
            if (value) {
              const filter = this.insuranceId
                ? [["code", value], "and", ["!", ["id", this.insuranceId]]]
                : ["code", value];
              const dupeCount = await InsuranceApi.searchStore.totalCount({
                filter
              });
              return dupeCount < 1;
            }
            return true;
          }
        },
        description: {
          required,
          maxLength: maxLength(250)
        },
        phoneNumber: {
          minLength: minLength(10)
        },
        addressLine1: {
          required
        },
        billingRateId: {
          required
        },
        city: {
          required
        },
        state: {
          required
        },
        zipCode: {
          required
        },
        effectiveOn: {
          required
        },
        expiryOn: {
          afterEffective
        }
      }
    };
  },
  created() {
    SettingsService.createSearch("/TypeCodes/Search", this.currentLab)
      .load({ filter: ["type.id", "=", 106] })
      .then(insuranceCategories => {
        this.insuranceCategories = insuranceCategories || []; //TODO - Insurance Category Type Codes need to be added
      });
    if (this.insuranceId) {
      this.isLoading = true;
      this.insuranceStore.byKey(this.insuranceId).then(response => {
        this.insurance = { ...response };
        this.originalInsurance = cloneDeep(response);
        this.isLoading = false;
      });
    }
    DropdownService.getBillingRates().then(res => {
      this.billingRates = res || [];
    });
  },
  mounted() {
    if (this.cloneData) {
      this.insurance = { ...this.insurance, ...this.cloneData };
    }
  },
  methods: {
    async handleCancel() {
      const confirm = await window.confirm("Are you sure you want to discard any data entered?");
      if (!confirm) {
        return;
      }
      return this.$emit("submit", this.insurance);
    },
    async handleSubmit() {
      this.$v.insurance.$touch();
      if (this.$v.insurance.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      const emptyInterfaceEntryIndices = this.insurance.interfaceMaps
        .filter(e => !e.receivingApp && !e.receivingFac && !e.emrInsurance)
        .map(e => e.index);
      if (emptyInterfaceEntryIndices.length) {
        for (const entryIndex of emptyInterfaceEntryIndices) {
          this.insurance.interfaceMaps = this.insurance.interfaceMaps.filter(
            e => e.index !== entryIndex
          );
        }
      }
      const anyChanges = JSON.stringify(this.insurance) !== JSON.stringify(this.originalInsurance);
      if (this.insuranceId) {
        //Deleting this object cause the dto just needs billingRateId
        delete this.insurance.billingRate;
        delete this.originalInsurance.billingRate;

        this.insurance = await InsuranceService.updateInsurance(this.insurance).catch(e => {
          if (e?.response?.data) {
            window.notify(e.response.data, "error");
          } else {
            window.notify("Error occurred with your submission.", "error");
          }
        });
        const logItem = createLogItem({}, 4);
        if (anyChanges) {
          logItem.comments = `${this.insuranceId}:${createLogComment(
            this.originalInsurance,
            this.insurance
          )}`;
        } else {
          logItem.comments = "No changes tracked.";
        }
        auditLog.insertLogMessage(logItem);
      } else {
        this.insurance = await InsuranceService.addInsurance({
          ...this.insurance,
          labId: this.currentLab
        }).catch(e => {
          if (e?.response?.data) {
            window.notify(e.response.data, "error");
          } else {
            window.notify("Error occurred with your submission.", "error");
          }
        });
        const logItem = createLogItem({}, 5);
        auditLog.insertLogMessage(logItem);
      }
      return this.$emit("submit", this.insurance);
    },
    addInterfaceMap() {
      const hasEmpty = this.insurance.interfaceMaps.find(
        e => e.receivingApp === null && e.receivingFac === null && e.emrInsurance === null
      );
      if (hasEmpty) {
        return;
      }
      this.insurance.interfaceMaps.push({
        index: this.insurance.interfaceMaps.length,
        receivingApp: null,
        receivingFac: null,
        emrInsurance: null
      });
      setTimeout(() => {
        this.grid.editCell(this.insurance.interfaceMaps.length - 1, "receivingApp");
      }, 100);
    },
    handleCellUpdate(data, values) {
      console.log(data);
      console.log(values);
      return data;
    },
    initGrid({ component }) {
      this.grid = component;
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      states: state => state.dropdowns.states
    }),
    isEditing() {
      return Boolean(this.insuranceId);
    },
    validatorMsgMap() {
      return { ...validatorMsgMapBase, unique: "This code is already registered." };
    },
    editing() {
      return {
        allowUpdating: true,
        mode: "cell",
        refreshMode: "repaint",
        selectTextOnEditStart: true
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.file_input {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.template_render {
  width: 100%;
  height: 225px;
  overflow: auto;
}
</style>
