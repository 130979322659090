<template>
  <container class="p-1">
    <dx-grid-with-search
      title="Insurance Company Maintenance"
      gridName="insurance"
      :columns="columns"
      :dataSource="insuranceStore"
      :toolbar="toolbar"
      @initialized="initializeGrid"
    >
      <template v-slot:extraActions>
        <div class="d-flex justify-content-end align-items-center">
          <Checkbox
            class="mr-3"
            v-model="showExpired"
            name="showExpired"
            id="showExpired"
            label="Show Expired"
          />
          <add-button v-if="permissions.InsuranceCodeCreateEdit" @click="createInsurance" />
        </div>
      </template>

      <template v-slot:actions="{ data }">
        <div>
          <icon-button
            class="m-auto text-primary pointer"
            :icon="'pen-alt'"
            @click="handleEditInsurance(data)"
            v-tooltip.left="'Edit Insurance'"
            v-if="permissions.InsuranceCodeCreateEdit"
          />
          <icon-button
            type="button"
            class="btn p-0 text-primary mx-1"
            v-tooltip.left="'Clone this insurance.'"
            @click="cloneInsurance(data.data)"
            :data-testid="`clone-insurance-${data.rowIndex}`"
            icon="copy"
            v-if="permissions.InsuranceCodeCreateEdit"
          />
        </div>
      </template>
      <template v-slot:expirationCell="{ data }">
        <div class="align-items-center">
          <ExpiryTemplate :expiry-date="data.value" />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isOpen" @close="closeModal">
      <insurance-maintenance-form
        :insuranceId="selectedInsuranceId"
        @submit="closeModal"
        :cloneData="cloneData"
      />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "../../common/DxGridWithSearch.vue";
import { mapGetters, mapState } from "vuex";
import AddButton from "../../common/AddButton.vue";
import auditLog from "../../../services/AuditLog";
import { createLogItem, dateRangeFilter, formatPhoneNumber } from "../../../modules/helpers";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import { InsuranceApi, SettingsApi } from "@/services";
import Container from "../../common/Container.vue";
import Modal from "@/components/common/Modal.vue";
import InsuranceMaintenanceForm from "./InsuranceMaintenanceForm.vue";
import DataSource from "devextreme/data/data_source";
import IconButton from "@/components/common/IconButton.vue";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  metaInfo: {
    title: "Insurance Company Maintenance",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    DxGridWithSearch,
    AddButton,
    ExpiryTemplate,
    Container,
    Modal,
    InsuranceMaintenanceForm,
    IconButton,
    Checkbox
  },
  data: () => ({
    grid: {},
    insuranceTypes: [],
    availableLabs: [],
    isLoading: false,
    selectedInsuranceId: null,
    isOpen: false,
    typeCodes: SettingsApi.typeCodesSearch,
    cloneData: {},
    showExpired: false
  }),

  mounted() {
    this.typeCodes.load({ filter: ["type.id", "=", 106] }).then(res => {
      this.insuranceTypes = res;
    });
    if (!this.states?.length) {
      this.$store.dispatch("dropdowns/getStates");
    }
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin Insurance page."
    });
  },

  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      states: state => state.dropdowns.states
    }),
    ...mapGetters(["permissions"]),
    columns() {
      return [
        {
          dataField: "code"
        },
        {
          dataField: "description"
        },
        {
          dataField: "addressLine1",
          caption: "Address"
        },
        {
          dataField: "city"
        },
        {
          dataField: "state",
          lookup: {
            dataSource: this.states,
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        {
          dataField: "zipCode",
          caption: "Zip"
        },
        {
          dataField: "phoneNumber",
          caption: "Phone",
          calculateCellValue({ phoneNumber }) {
            if (phoneNumber) {
              return formatPhoneNumber(phoneNumber);
            }
            return "";
          }
        },
        {
          dataField: "insuranceCategoryId",
          caption: "Category",
          allowSearch: false,
          lookup: {
            dataSource: this.insuranceTypes,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "providerId",
          caption: "Provider ID"
        },
        {
          dataField: "billingRate.code",
          caption: "Billing Rate",
          allowSearch: false,
          allowSorting: false
        },
        {
          dataField: "group"
        },
        { dataField: "effectiveOn", dataType: "date" },
        { dataField: "expiryOn", dataType: "date", cellTemplate: "expirationCell" },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            template: "extraActions",
            locateInMenu: "auto"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    },
    insuranceStore() {
      return new DataSource({
        store: InsuranceApi.searchStore,
        filter: this.showExpired ? [] : dateRangeFilter()
      });
    }
  },
  methods: {
    initializeGrid(event) {
      this.grid = event.component;
    },
    handleEditInsurance(data) {
      this.selectedInsuranceId = data.key;
      this.isOpen = true;
    },
    closeModal() {
      this.selectedInsuranceId = null;
      this.isOpen = false;
      this.insuranceStore.reload();
      this.cloneData = {};
    },
    createInsurance() {
      this.selectedInsuranceId = null;
      this.isOpen = true;
    },
    cloneInsurance(data) {
      this.cloneData = { ...data, description: "", code: "" };
      delete this.cloneData.id;
      delete this.cloneData.billingRate;
      this.isOpen = true;
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
